<template>
  <div class="p-4">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-12 text-left">
            <h4>Police checks</h4>
          </div>
        </div>
      </div>

      <div class="card-body">
        <!-- Desktop view -->
        <div class="desktop-view">
          <div class="search-row">
            <div class="search-box">
              <div class="input-group form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  aria-describedby="basic-addon2"
                  v-model.lazy="search"
                  v-on:keyup.enter="fnSearch()"
                />

                <div class="input-group-append">
                  <span
                    class="input-group-text"
                    id="basic-addon2"
                    @click="fnSearch()"
                    >Search....</span
                  >
                </div>
                <label for="paginate" class="floatingLabel">Search ...</label>
              </div>
            </div>

            <div class="export-box">
              <div class="filter-box">
                <div class="status-box">
                  <div class="form-group">
                    <select
                      v-model="selectedStatus"
                      class="form-control sits_select"
                    >
                      <option value="">All</option>
                      <option value="1">Draft</option>
                      <option value="2">New</option>
                      <option value="3">More Information Required</option>
                      <option value="7">Ready to Check</option>
                      <option value="4">Waiting For Police Review</option>
                      <option value="5">Completed</option>
                      <option value="6">Cancelled</option>
                    </select>
                    <label for="paginate" class="floatingLabel">Status</label>
                  </div>
                </div>

                <div class="date-box">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="date"
                      id="dateOfBirth"
                      autocomplete="off_dateOfBirth"
                      placeholder=" "
                      v-model="fromDate"
                    />
                    <label class="floatingLabel" for="dateOfBirth"
                      >From date</label
                    >
                  </div>
                </div>
                <div class="date-box">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="date"
                      id="dateOfBirth"
                      autocomplete="off_dateOfBirth"
                      placeholder=" "
                      v-model="toDate"
                    />
                    <label class="floatingLabel" for="dateOfBirth"
                      >To date</label
                    >
                  </div>
                </div>
                <div>
                  <button class="btn btn-sm btn-info" @click="fnSearch()">
                    Filter
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-sm btn-info"
                    @click="fnExport()"
                    :disabled="isExport"
                  >
                    Export
                  </button>
                </div>
                <div>
                  <button class="btn btn-sm btn-info" @click="fnClear()">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile view -->
        <div class="mobile-view">
          <div class="search-row">
            <div class="row">
              <div class="col-12">
                <div class="input-group form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    aria-describedby="basic-addon2"
                    v-model.lazy="search"
                    v-on:keyup.enter="fnSearch()"
                  />

                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      @click="fnSearch()"
                      >Search....</span
                    >
                  </div>
                  <label for="paginate" class="floatingLabel">Search ...</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <select
                    v-model="selectedStatus"
                    class="form-control sits_select"
                  >
                    <option value="">All</option>
                    <option value="1">Draft</option>
                    <option value="2">New</option>
                    <option value="3">More Information Required</option>
                    <option value="7">Ready to Check</option>
                    <option value="4">Waiting For Police Review</option>
                    <option value="5">Completed</option>
                    <option value="6">Cancelled</option>
                  </select>
                  <label for="paginate" class="floatingLabel">Status</label>
                </div>
              </div>
              <div class="col-12">
                <div class="filter-box">
                  <div class="date-box">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="date"
                        id="dateOfBirth"
                        autocomplete="off_dateOfBirth"
                        placeholder=" "
                        v-model="fromDate"
                      />
                      <label class="floatingLabel" for="dateOfBirth"
                        >From date</label
                      >
                    </div>
                  </div>
                  <div class="date-box">
                    <div class="form-group">
                      <input
                        class="form-control"
                        type="date"
                        id="dateOfBirth"
                        autocomplete="off_dateOfBirth"
                        placeholder=" "
                        v-model="toDate"
                      />
                      <label class="floatingLabel" for="dateOfBirth"
                        >To date</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="filter-box-right">
                  <div>
                    <button class="btn btn-sm btn-info" @click="fnSearch()">
                      Filter
                    </button>
                  </div>
                  <div>
                    <button
                      class="btn btn-sm btn-info"
                      @click="fnExport()"
                      :disabled="isExport"
                    >
                      Export
                    </button>
                  </div>
                  <div>
                    <button class="btn btn-sm btn-info" @click="fnClear()">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive table-sm policeCheckList">
          <!-- <table class="table table-bordered table-striped table-hover"> -->
          <table
            class="v-datatable-light table table-bordered table-hover table-striped table-center"
          >
            <thead>
              <tr>
                <th></th>
                <th class="header-item header-column-0">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('id')"
                    >Id</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'id'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'id'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>

                <th class="header-item header-column-1">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('first_name')"
                    >First name</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'first_name'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'first_name'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-2">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('last_name')"
                    >Last name</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'last_name'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'last_name'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('product_id')"
                    >Product</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'product_id'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'product_id'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>

                <th class="header-item header-column-3">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('payment_ref')"
                    >Payment ref.</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'payment_ref'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'payment_ref'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-5 text-center">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('add_by_pc_role_id')"
                    >Category</a
                  >
                  <span
                    v-if="
                      sortDirection == 'desc' &&
                      sortField == 'add_by_pc_role_id'
                    "
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="
                      sortDirection == 'asc' && sortField == 'add_by_pc_role_id'
                    "
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-5 text-center">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('stakeholder')"
                    >Stakeholder</a
                  >
                  <span
                    v-if="sortDirection == 'desc' && sortField == 'stakeholder'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span
                    v-if="sortDirection == 'asc' && sortField == 'stakeholder'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>

                <th class="header-item header-column-5 text-center">
                  <a
                    href="javascript:void(0)"
                    @click.prevent="fnChangeSort('status')"
                    >Status</a
                  >
                  <span v-if="sortDirection == 'desc' && sortField == 'status'"
                    ><i class="fas fa-sort-down"></i
                  ></span>
                  <span v-if="sortDirection == 'asc' && sortField == 'status'"
                    ><i class="fas fa-sort-up"></i
                  ></span>
                </th>
                <th class="header-item header-column-7 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(item, index) in getPoliceCheckListPaginate.data"
              >
                <tr :key="(item, index)">
                  <td
                    @click="toggle(index)"
                    :class="{ opened: opened.includes(index) }"
                  >
                    <span class="text-info pl-2"
                      ><i class="fas fa-outdent"></i
                    ></span>
                  </td>
                  <td class="column-0">{{ item.id }}</td>
                  <td class="column-1">{{ item.first_name }}</td>
                  <td class="column-2">{{ item.last_name }}</td>
                  <td class="column-4">{{ item.product_name }}</td>
                  <td class="column-6">{{ item.payment_ref }}</td>
                  <td class="column-7 text-center">
                    <span
                      v-if="item.add_by_pc_role_id == 3"
                      class="badge badge-warning"
                      >Individual</span
                    >
                    <span
                      v-if="
                        item.add_by_pc_role_id == 4 ||
                        item.add_by_pc_role_id == 5 ||
                        item.add_by_pc_role_id == 9
                      "
                      class="badge badge-info"
                      >Corporate</span
                    >
                    <span
                      v-if="
                        item.add_by_pc_role_id == 6 ||
                        item.add_by_pc_role_id == 7 ||
                        item.add_by_pc_role_id == 8
                      "
                      class="badge badge-dark"
                      >Partner</span
                    >
                  </td>

                  <td class="column-7 text-center">
                    {{ item.stakeholder }}
                  </td>

                  <td class="column-7 text-center">
                    <span v-if="item.status == 1" class="badge badge-secondary"
                      >Draft</span
                    >
                    <span v-if="item.status == 2" class="badge badge-primary"
                      >New</span
                    >
                    <span v-if="item.status == 3" class="badge badge-warning"
                      >MIR</span
                    >
                    <span v-if="item.status == 4" class="badge badge-info"
                      >WFPR</span
                    >
                    <span v-if="item.status == 5" class="badge badge-success"
                      >Completed</span
                    >
                    <span v-if="item.status == 6" class="badge badge-dark"
                      >Cancelled</span
                    >
                    <span v-if="item.status == 7" class="badge badge-light"
                      >Ready to Check</span
                    >
                  </td>
                  <td class="column-8">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <!-- View job -->
                      <a
                        v-scroll-to="'#policeCheckView'"
                        @click="fnGoPoliceCheckView(item)"
                        class="d-inline pr-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View"
                      >
                        <i class="far fa-eye text-info fs-800"></i>
                      </a>

                      <!-- Edit job -->
                      <button
                        v-scroll-to="'#policeCheckEditByAdmin'"
                        class="btn btn-sm d-inline pr-1"
                        @click="fnGoPoliceCheckEdit(item)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                      >
                        <i class="far fa-edit text-info fs-800"></i>
                      </button>

                      <!-- Download XML -->
                      <div>
                        <span v-if="item.status == 7">
                          <button
                            class="btn btn-sm d-inline pr-1"
                            @click="fnDownloadPoliceCheckXML(item.id)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download XML"
                          >
                            <i class="fas fa-download text-info fs-800"></i>
                          </button>
                        </span>

                        <span
                          v-else
                          class="d-inline pr-2 pl-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Download XML"
                        >
                          <i class="fas fa-hand-paper text-info fs-800"></i>
                        </span>
                      </div>

                      <!-- Upload Police check from NSS -->
                      <div>
                        <span v-if="item.status == 4 || item.status == 5">
                          <appModalUploadPoliceCheckFromNSS
                            class="d-inline pr-1 pl-2"
                            :propsData="item"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Upload NSS certificate"
                          ></appModalUploadPoliceCheckFromNSS>
                        </span>

                        <span
                          v-else
                          class="d-inline pr-2 pl-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Upload NSS certificate"
                        >
                          <i class="fas fa-hand-paper text-info fs-800"></i>
                        </span>
                      </div>

                      <!-- To View Uploaded Police check which came from NSS -->
                      <div>
                        <span v-if="item.status == 4 || item.status == 5">
                          <button
                            class="btn btn-sm d-inline pr-1"
                            @click="fnDownloadPoliceCheckNSS(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View NSS certificate"
                          >
                            <i class="fas fa-file-invoice text-info fs-800"></i>
                          </button>
                        </span>

                        <span
                          v-else
                          class="d-inline pr-2 pl-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View NSS certificate"
                        >
                          <i class="fas fa-hand-paper text-info fs-800"></i>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="opened.includes(index)">
                  <td colspan="10">
                    <div class="row mr-2 ml-2">
                      <div class="col-lg-6 col-md-6 col-12">
                        <dl class="row">
                          <dd class="col-sm-5 fw-500">Email</dd>
                          <dd class="col-sm-7">{{ item.email }}</dd>
                          <dd class="col-sm-5 fw-500">Fee</dd>
                          <dd class="col-sm-7">
                            {{ item.fee }}
                          </dd>
                          <dd class="col-sm-5 fw-500">Payment Date</dd>
                          <dd class="col-sm-7">
                            {{ item.payment_date | dateConvertDDMMYYYY }}
                          </dd>
                        </dl>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12"></div>
                    </div>
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="10">
                  <div class="sits-pagination-row">
                    <div class="items-per-page">
                      <div class="pr-2">
                        <p class="mb-0">Items per page</p>
                      </div>
                      <div class="pr-5">
                        <select
                          v-model="paginate"
                          class="form-control sits_select"
                        >
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>

                    <div>
                      <pagination
                        :limit="5"
                        :data="getPoliceCheckListPaginate"
                        @pagination-change-page="fnPaginate"
                      ></pagination>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="policeCheckIdView" id="policeCheckView">
          <policeCheckViewCom
            :propsData="getPoliceCheckSingle"
            :propsNotesData="getNotesList"
          ></policeCheckViewCom>
        </div>

        <div v-if="policeCheckIdEdit" id="policeCheckEditByAdmin">
          <appPoliceCheckStepsEditByAdmin
            :policeCheckId="policeCheckIdEdit"
            :productId="productId"
            :propsData="getPoliceCheckSingle"
            :propsNotesData="getNotesList"
          >
          </appPoliceCheckStepsEditByAdmin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store/modules/base.module";
import * as authService from "../../services/auth.service";
import pagination from "laravel-vue-pagination";
import * as messageService from "../../services/message.service";
import { mapGetters, mapActions } from "vuex";
import appPoliceCheckStepsEditByAdmin from "../../components/policeCheck/policeCheckStepsEditByAdmin.vue";
import policeCheckViewCom from "../../components/policeCheck/policeCheckViewCom.vue";
import appModalUploadPoliceCheckFromNSS from "../../components/policeCheck/ModalUploadPoliceCheckFromNSS.vue";
import * as miscellaneousService from "../../services/miscellaneous.service";

export default {
  name: "PoliceCheckListPaginate",
  components: {
    pagination,
    appPoliceCheckStepsEditByAdmin,
    policeCheckViewCom,
    appModalUploadPoliceCheckFromNSS,
  },
  data() {
    return {
      loginUser: {},
      opened: [],
      policeCheckIdView: "",
      policeCheckIdEdit: "",
      customerIdByAdmin: "",
      productId: "",

      policeCheckListPaginate: {},
      paginate: 15,
      currentPage: 1,
      belongsTo: "",
      search: "",
      selectedStatus: "",
      fromDate: "",
      toDate: "",
      sortDirection: "desc",
      sortField: "id",
      isExport: true,
    };
  },
  computed: {
    ...mapGetters([
      "getPoliceCheckListPaginate",
      "getPoliceCheckSingle",
      "gettStepId",
      "getNotesList",
    ]),
    paginateObj() {
      let obj = {};
      obj = {
        currentPage: this.currentPage,
        paginate: this.paginate,
        belongsTo: this.belongsTo,
        search: this.search,
        selectedStatus: this.selectedStatus,
        fromDate: this.fromDate,
        toDate: this.toDate,
        sortDirection: this.sortDirection,
        sortField: this.sortField,
      };

      return obj;
    },
  },
  methods: {
    ...mapActions([
      "fetchPoliceCheckListPaginate",
      "fetchPoliceCheckSingle",
      "fetchStepId",
      "fetchNotesList",
      "fetchNotesPublicList",
    ]),

    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },

    fnPaginate: async function (currentPage = 1) {
      try {
        this.currentPage = currentPage;
        this.fetchPoliceCheckListPaginate(this.paginateObj);
      } catch (error) {
        const obj = {
          type: "error",
          title: error.response.data.error.name,
          text: error.response.data.error.message,
          footer: "Error code: " + error.response.data.error.statusCode,
        };

        messageService.fnSweetAlertDisplay(obj);
      }
    },

    // Export Police check list Summary into excel
    getExportPoliceCheckListSummary: function () {
      let belongsTo = "";
      let search = "";
      let selectedStatus = "";
      let fromDate = "";
      let toDate = "";

      if (this.belongsTo == "") {
        belongsTo = null;
      } else {
        belongsTo = this.belongsTo;
      }
      if (this.search == "") {
        search = null;
      } else {
        search = this.search;
      }
      if (this.selectedStatus == "") {
        selectedStatus = null;
      } else {
        selectedStatus = this.selectedStatus;
      }
      if (this.fromDate == "") {
        fromDate = null;
      } else {
        fromDate = this.fromDate;
      }
      if (this.toDate == "") {
        toDate = null;
      } else {
        toDate = this.toDate;
      }

      window.open(
        `${store.state.apiURL}/police-check/list-export/${belongsTo}/${search}/${selectedStatus}/${fromDate}/${toDate}`
      );

      messageService.fnSweetAlertSuccessToast(
        "Police Check Summary",
        "Downloaded successfully"
      );
    },

    fnChangeSort(field) {
      if (this.sortField == field) {
        this.sortDirection = this.sortDirection == "asc" ? "desc" : "asc";
      } else {
        this.sortField = field;
      }
      this.fetchPoliceCheckListPaginate(this.paginateObj);
    },

    fnSearch() {
      this.isExport = true;
      this.fetchPoliceCheckListPaginate(this.paginateObj);
      this.isExport = false;
    },

    // EXport Police check list summary
    fnExport() {
      this.getExportPoliceCheckListSummary();
      this.isExport = true;
    },
    // To Clear Search keys
    fnClear() {
      this.currentPage = 1;
      this.search = "";
      this.selectedStatus = "";
      this.fromDate = "";
      this.toDate = "";
      this.sortDirection = "desc";
      this.sortField = "id";
      this.isExport = true;
      this.fetchPoliceCheckListPaginate(this.paginateObj);
    },

    fnGoPoliceCheckView(props) {
      console.log(props);
      this.policeCheckIdEdit = "";
      this.policeCheckIdView = "";
      this.customerIdByAdmin = "";

      this.policeCheckIdView = props.id;
      this.productId = props.product_id;

      this.fetchPoliceCheckSingle(props.id);
      this.fetchNotesList(props.noteId);
      this.fetchNotesPublicList(props.notePublicId);
    },
    fnGoPoliceCheckEdit(props) {
      this.fetchPoliceCheckSingle(props.id);

      this.policeCheckIdEdit = "";
      this.policeCheckIdView = "";
      this.customerIdByAdmin = "";

      this.policeCheckIdEdit = props.id;
      this.customerIdByAdmin = props.user_id;
      this.productId = props.product_id;

      const obj = {
        checkId: props.id,
        productId: props.product_id,
      };
      this.fetchNotesList(obj);

      this.fetchStepId(1);
    },
    fnDownloadPoliceCheckXML(id) {
      // Download finance doc
      window.open(
        `${store.state.apiURL}/police-check/download-police-check/` + id
      );
    },

    // Download (view) final certificate
    fnDownloadPoliceCheckNSS: async function (policeCheck) {
      let url = "";
      url = await miscellaneousService.fnGetTempUrl(
        `${policeCheck.police_check_certificate_from_nss}`
      );
      window.open(url);
    },
  },
  created() {
    this.fetchPoliceCheckListPaginate(this.paginateObj);
    this.loginUser = authService.getUserFromToken();
  },
};
</script>

<style lang="scss" scoped>
.sits-btn-round.btn.btn-info {
  border-radius: 50% !important;
  padding-top: 0.2rem !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
.search-row {
  display: flex;
  justify-content: space-between;

  .search-box {
    width: 450px;
  }

  .filter-box {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: baseline;
  }

  .export-box {
    display: flex;
  }

  .status-box {
    width: 350px;
  }
  .date-box {
    max-width: 200px;
  }

  .input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.275rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #20bcee;
    border: 0px solid #ced4da;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}
</style>
