import { render, staticRenderFns } from "./PoliceCheckList.vue?vue&type=template&id=bd6526da&scoped=true&"
import script from "./PoliceCheckList.vue?vue&type=script&lang=js&"
export * from "./PoliceCheckList.vue?vue&type=script&lang=js&"
import style0 from "./PoliceCheckList.vue?vue&type=style&index=0&id=bd6526da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd6526da",
  null
  
)

export default component.exports